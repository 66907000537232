<template>

    <div class="manage-translations">

        <md-dialog-alert
                :md-active.sync="showErrorPopup"
                :md-content.sync="importError"
                @click="showErrorPopup = false"
                md-confirm-text="Ok" />

        <div class="groups">
            <template v-for="(gr, k) in groups">
                <a :key="k" href="#" :class="' item ' + (currentGroup==gr ? ' active ' : '') " @click.prevent="setGroup(gr)">{{gr}}</a>
            </template>
        </div>

        <h1>Translations</h1>

        <div>
            <md-button :href="$api.getBaseUrl().replace(/\/\s*$/, '') + '/api/translation/export/'" target="_blank" class="md-button md-raised md-primary">Export translations</md-button>
            <md-button class="md-raised md-accent" @click="showImportWrap = !showImportWrap">Import translations</md-button>

            <div class="import-wrap" v-if="showImportWrap">
                <form action="#" method="post" class="md-layout" @submit.prevent="importTranslations">
                    <md-field>
                        <label>Upload files</label>
                        <md-file @change="setFile" v-model="fileAtr" ref="mdfile" placeholder="Choose csv file to import" />
                    </md-field>
                    <md-button :disabled="!file" class="md-raised md-accent" type="submit">Upload</md-button>
                </form>
            </div>
        </div>

        <md-app-content>
            <div class="md-layout md-gutter">
                <div class="md-layout-item md-size-15">alias</div>

                <div class="md-layout-item">
                    <md-field>
                        <label for="eta">Example language</label>
                        <md-select id="eta" v-model="etaLanguage"><md-option v-for="lang in languages" :key="'eta-'+lang.id" :value="lang.id">{{lang.language}}</md-option></md-select>
                    </md-field>
                </div>
                <div class="md-layout-item">
                    <md-field>
                        <label for="act">Editable language</label>
                        <md-select id="act" v-model="activeLanguage"><md-option v-for="lang in languages" :key="'act-'+lang.id" :value="lang.id">{{lang.language}}</md-option></md-select>
                    </md-field>

                </div>
            </div>

            <template v-for="(alias, k) in aliases">
                <div :key="k" class="md-layout md-gutter item">

                    <div class="md-layout-item md-size-15 label">
                        <a @click="deleteAlias(alias)"><md-icon>delete</md-icon></a>
                        {{alias}}
                    </div>

                    <div class="md-layout-item editable"><textarea @change="update(alias, etaLanguage, $event)" :value='$get("translation/get", alias + "/" + etaLanguage)'></textarea></div>
                    <div class="md-layout-item editable"><textarea @change="update(alias, activeLanguage, $event)" :value='$get("translation/get", alias + "/" + activeLanguage)'></textarea></div>
                </div>
            </template>

        </md-app-content>

        <md-button class="md-fab md-primary md-fab-bottom-right" @click="add">
            <md-icon>add</md-icon>
        </md-button>
    </div>
</template>

<script>


    export default {

        // Data
        data()
        {
            // Current tab
            return {
                activeLanguage: 'en',
                etaLanguage: 'en',
                currentGroup: "common",
                showDialog: false,
                task_id: 0,
                showImportWrap: false,
                file: null,
                importError: '',
                fileAtr: null,
                showErrorPopup: false,
                // max file size MB
                maxFileSize: 5
            };
        },


        // Computed
        computed:
            {
                // get tasks list
                list()
                {
                    // Get list
                    return this.$get("translation/list", this.currentGroup);
                },

                // get translation groups
                groups()
                {
                    // Get list
                    return this.$get("translation/groups");
                },

                // get languages
                languages()
                {
                    // Get list
                    return this.$get("translation/languages");
                },

                // get aliases
                aliases()
                {
                    // Get list
                    return this.$get("translation/aliases", this.currentGroup);
                }
            },

        // Mounted
        mounted()
        {


            // Load languages
            this.$dispatch("translation/loadLanguages");

        },

        // Methods
        methods:
            {

                /**
                 * Set group
                 */

                setGroup(gr)
                {
                    // Set group
                    this.currentGroup = gr;
                },

                /**
                 * Add new task
                 */
                add()
                {
                    // Ask for new alias
                    let alias = prompt("Please type new alias");

                    // Check is alias already set
                    if(this.$get("translation/exists", alias))
                    {
                        // Show error
                        alert("Alias already exists.");
                        return;
                    }

                    // Add translation
                    if(alias) this.$dispatch("translation/set", {id: alias, language: 'en', value: '...', group: this.currentGroup});
                },

                /**
                 * Update member
                 * @param alias
                 * @param language
                 * @param ev
                 */
                update(alias, language, ev)
                {
                    // Store translation
                    this.$dispatch("translation/set", {id: alias, language: language, value: ev.target.value, group: this.currentGroup});
                },

                /**
                 * Delete alias
                 * @param alias
                 */
                deleteAlias(alias)
                {
                    // Delete translation
                    if (confirm("Are you sure want to delete alias?")) this.$dispatch("translation/delete", alias);
                },

                setFile(e)
                {
                    if (e.target.files[0].size/1024/1024 > this.maxFileSize) {
                        this.showErrorPopup = true
                        this.importError = `Max file size is ${this.maxFileSize} Mb`
                        this.fileAtr = null;
                        return false;
                    }
                    this.file = e.target.files[0] || null
                },

                importTranslations()
                {
                    let fd = new FormData()
                    fd.append('translations', this.file)

                    this.$api.call(null, 'post', '/api/admin/translation/import/', fd).then(res => {
                        if (res.status !== 'ok') {
                            this.showErrorPopup = true
                            this.importError = res.message
                        } else {
                            this.showErrorPopup = true
                            this.importError = 'Translations loaded successfully'
                            setTimeout(() => {
                                // Load translations
                                this.$dispatch("translation/loadAll");

                                // Load languages
                                this.$dispatch("translation/loadLanguages");
                            }, 100)
                        }
                        this.fileAtr = null;
                        this.file = null;
                    }, err => {
                        this.importError = err.message
                        this.showErrorPopup = true
                        this.fileAtr = null;
                        this.file = null;
                    })
                }

            }
    }
</script>

<style lang="scss">

    .manage-translations
    {
        padding-bottom: 100px;

        // One translation item
        .item
        {
            min-height: 35px;
            margin-bottom:2px;
        }

        .label
        {
            line-height: 35px;
            background: #f5f5f5;
        }

        // Input styles
        .editable textarea
        {
            min-height: 35px;
            width: 100%;
            border:0px;
            font-size:1em;
            border-bottom:1px solid #aaa;
        }

        // Groups list
        .groups
        {
            float:right;

            // Group item
            .item
            {
                background: #eee;
                padding:5px;
                margin-right: 10px;
            }

            // Active group
            .item.active {background: #a00;color:white;}
        }
    }


</style>

